import Cookies from 'js-cookie'
import { ACCESS_TOKEN } from './variable'

// 获取token
export function getToken() {
  return Cookies.get(ACCESS_TOKEN)
}

// 设置token
export function setToken(token) {
  return Cookies.set(ACCESS_TOKEN, token)
}

// 移除token
export function removeToken() {
  return Cookies.remove(ACCESS_TOKEN)
}

/**
 * 存储sessionStorage
 */
export const setSessionStorage = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.sessionStorage.setItem(name, content)
}

/**
 * 获取sessionStorage
 */
export const getSessionStorage = name => {
  if (!name) return
  return window.sessionStorage.getItem(name)
}

/**
 * 删除sessionStorage
 */
export const removeSessionStorage = name => {
  if (!name) return
  window.sessionStorage.removeItem(name)
}

/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}

/**
 * 获取localStorage
 */
export const getStore = name => {
  if (!name) return
  return window.localStorage.getItem(name)
}

/**
 * 删除localStorage
 */
export const removeStore = name => {
  if (!name) return
  window.localStorage.removeItem(name)
}

/**
 * 写cookies
 * config 具体配置参考js-cookie插件文档
 */
export const setCookie = (name, content, config = {}) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  return Cookies.set(name, content, config)
}

/**
 * 读取cookies
 */
export const getCookie = name => {
  if (!name) return
  return Cookies.get(name)
}

/**
 * 删除cookies
 */
export const removeCookie = name => {
  if (!name) return
  return Cookies.remove(name)
}
