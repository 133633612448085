import {
  postMerchantPasswordLogin,
  postMerchantCodeLogin,
  getMerchantInfo,
  postMerchantRegister,
  postForgetMerchantPassword,
  getCheckActionUser,
  getSyncUserCard,
} from '@/api'
import { MessageBox, Message } from 'element-ui'
import {
  getToken,
  setToken,
  removeToken,
  getCookie,
  setCookie,
  removeCookie,
} from '@/storage'
import { MERCHANT_INFO } from '@/storage/variable'

export default {
  namespaced: true,
  state: {
    // 商户信息
    merchantInfo: JSON.parse(getCookie(MERCHANT_INFO) || '{}'),
    // 是否显示登录
    isShowLogin: false,
    // 是否为点击注册
    isRegister: false,
    // 令牌
    token: getToken(),
  },
  mutations: {
    SET(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },
  },
  actions: {
    // 验证环境及跳转
    async verifyEnvJump({}, data) {
      return new Promise((resolve, reject) => {
        const url = window.location.host
        if (url != 'www.huodongge.cn') {
          window.location.href =
            'https://www.huodongge.cn/official/#/?type=' +
            data +
            '&secret_key=' +
            new Date().valueOf()
        }
        resolve()
      })
    },
    // 打开登录弹窗
    async showLogin({ dispatch, commit }) {
      await dispatch('verifyEnvJump', 'login')
      setTimeout(() => {
        commit('SET', { isShowLogin: true, isRegister: false })
      }, 50)
    },
    // 打开注册弹窗
    async showRegister({ dispatch, commit }) {
      await dispatch('verifyEnvJump', 'register')
      setTimeout(() => {
        commit('SET', { isShowLogin: true, isRegister: true })
      }, 50)
    },
    // 密码登录
    async passwordLogin({ dispatch, commit }, params) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await postMerchantPasswordLogin(params)
          const downList = await dispatch(
            'common/getDomain',
            {},
            { root: true }
          )
          resolve(res)
          if (res.status == 0) {
            setToken(res.data.token)
            commit('SET', { token: res.data.token, isShowLogin: false })
            await dispatch('getInfo', true)
            // 登陆成功
            dispatch('common/goModelMarket', {}, { root: true })
          }
        } catch (error) {
          reject(error)
        }
      })
    },

    // 验证码登录
    codeLogin({ dispatch, commit }, params) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await postMerchantCodeLogin(params)
          const downList = await dispatch(
            'common/getDomain',
            {},
            { root: true }
          )
          resolve(res)
          if (res.status == 0) {
            setToken(res.data.token)
            commit('SET', { token: res.data.token, isShowLogin: false })
            await dispatch('getInfo', true)
            // 登陆成功
            dispatch('common/goModelMarket', {}, { root: true })
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    // 微信登陆
    wechatLogin({ dispatch, commit }, res, status = true) {
      return new Promise(async (resolve, reject) => {
        try {
          const downList = await dispatch(
            'common/getDomain',
            {},
            { root: true }
          )
          resolve(res)
          setToken(res.data.cache.token)
          commit('SET', { token: res.data.cache.token, isShowLogin: false })
          await dispatch('getInfo', true)
          if (status) {
            // 登陆成功
            dispatch('common/goModelMarket', {}, { root: true })
            return
          }
        } catch (error) {
          reject(error)
        }
      })
    },

    // 用户注册
    userRegister({ dispatch, commit }, params) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await postMerchantRegister(params)
          resolve(res)
          if (res.status == 0) {
            setToken(res.data.token)
            commit('SET', { token: res.data.token, isShowLogin: true })
            await dispatch('getInfo', true)
            dispatch('common/goModelMarket', {}, { root: true })
          }
        } catch (error) {
          reject(error)
        }
      })
    },

    // 用户找回密码
    userRetrieve({ dispatch, commit }, params) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await postForgetMerchantPassword(params)
          resolve(res)
        } catch (error) {
          reject(error)
        }
      })
    },
    /**
     * @title 获取商户信息
     * @param {Boolean} refresh true=每次重新获取, false=有则本地拿, 无则服务器重新获取
     */
    getInfo({ commit, state, dispatch }, refresh = false, source = 'creator') {
      return new Promise(async (resolve, reject) => {
        const merchantInfo = getCookie(MERCHANT_INFO) || {}
        try {
          if (Object.keys(merchantInfo).length > 0 && refresh == false) {
            resolve({
              status: 0,
              data: JSON.parse(JSON.stringify(merchantInfo)),
            })
          } else if (state.token) {
            await setCookie('token', state.token)
            await dispatch('verifyIdentity')
            const res = await getMerchantInfo({ source, token: state.token })
            if (res.status == 0) {
              commit('SET', { merchantInfo: res.data })
              setCookie(MERCHANT_INFO, JSON.stringify(res.data))
              if (res.data.shop_info && res.data.shop_info.shop_id) {
                setCookie('shop_id', res.data.shop_info.shop_id)
              }
            }
            resolve(res)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    // 校验用户是否有平台身份
    async verifyIdentity({ state, dispatch, commit }, token) {
      if (!state.token) return
      let res = await getCheckActionUser({
        source: 'creator',
        token: state.token,
      })
      if (res.status) return
      if (!res.data.is_check) {
        await getSyncUserCard({
          source: 'creator',
          token: state.token,
        })
      }
    },
    // 删除用户token
    resetToken({}) {
      return new Promise((resolve) => {
        removeToken()
        removeCookie(MERCHANT_INFO)
        resolve(true)
      })
    },
  },
}
