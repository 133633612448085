/*
 * @param {val} key-id || ''   22.10.28 袁应支持url，默认不传
 */
export const getId = function (key, flag, url = '') {
  if (!url) url = window.location.href
  let id = ''
  if ((url.includes('?' + key) || url.includes('&' + key)) && key != '') {
    id = url.split(key + '=')[1].split('&')[0]
    if (flag) {
      return '&' + key + '=' + id
    }
  }
  return decodeURI(id)
}
