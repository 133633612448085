import {
  postMerchantLoginCms,
  getDomainList,
  getBusinessPicture,
} from '@/api/index'
import { setStore, getStore } from '@/storage'
import { DOMAIN_LIST, HOMETIME } from '@/storage/variable'
export default {
  namespaced: true,
  state: {
    domainList: JSON.parse(getStore(DOMAIN_LIST)), //域名列表
    businessList: [], //机构行业大类
  },
  mutations: {
    SET(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },
  },
  actions: {
    /**
     * 获取验证码
     * @param {object} params 接口参数
     * cms_type 0注册 1登录 2密码找回 5验证手机号
     *
     */
    getCode({}, params) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await postMerchantLoginCms(params)
          resolve(res)
        } catch (error) {
          reject(error)
        }
      })
    },
    /**
     * 获取机构行业大类
     *
     */
    getBusinessList({ state, commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          if (state.businessList.length) return resolve(state.businessList)
          const res = await getBusinessPicture()
          if (res.status === 0) {
            commit('SET', { businessList: res.data })
            resolve(res.data)
          } else {
            reject()
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    /**
     * 获取域名
     *
     */
    getDomain({ state, commit }, params) {
      return new Promise(async (resolve, reject) => {
        try {
          if (!state['domainList']) {
            const res = await getDomainList(params)
            setStore(DOMAIN_LIST, res.data)
            commit('SET', { domainList: res.data })
            resolve(res.data)
          } else {
            resolve(state['domainList'])
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    /**
     * @title 腾讯防水墙验证
     * @param {Boolean} refresh true=初始化防水墙, false=启动防水墙
     */
    async getTencentCheck({ state, commit }, refresh = false) {
      return new Promise((resolve, reject) => {
        try {
          // 初始化防水墙
          if (window.TencentCaptcha === undefined && refresh) {
            const script = document.createElement('script')
            const head = document.getElementsByTagName('head')[0]
            script.type = 'text/javascript'
            script.charset = 'UTF-8'
            script.src = 'https://turing.captcha.qcloud.com/TCaptcha.js'
            head.appendChild(script)
            resolve()
          }
          // 启动防水墙
          if (window.TencentCaptcha && !refresh) {
            const captcha = new window.TencentCaptcha(
              process.env.VUE_APP_CAPTCHA_APPID,
              async (res) => {
                resolve(res)
              }
            )
            captcha.show()
          }
        } catch (error) {
          reject()
        }
      })
    },
    // 跳转模型库
    goModelMarket({ state, commit, dispatch }, params) {
      return new Promise(async (resolve, reject) => {
        try {
          setStore(HOMETIME, new Date().getTime())
          window.location.href = '/model-market/#/homePage'
        } catch (error) {
          reject(error)
        }
      })
    },
  },
}
